import { createSlice } from '@reduxjs/toolkit';

const viewPersistenceSlice = createSlice({
    name: "viewPersistence",
    initialState: {
      feature: 'core',
    },
    reducers: {
        saveLastVisited(state, action) {
            state.feature = action.payload;
        }
    },
});

export default viewPersistenceSlice;