// utility function that returns the projectIdentifier from localStorage

const getProjectIdentifier = () => {
    // // if we have projectIdentifier in local storage, use it
    // console.log("getProjectIdentifier!!");
    // if (localStorage.getItem('projectIdentifier') !== null) {
    //     if (localStorage.getItem('projectIdentifier') !== "undefined") {
    //         return localStorage.getItem('projectIdentifier');
    //     }
    // }


    return "472d11e2-3038-4971-8ea9-6ae242563672";
}

export default getProjectIdentifier