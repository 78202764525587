import React, { useState, useRef, useEffect } from 'react';
import './chatbot.css'; // Import your CSS file here
import VirtualAssistant from './virtualassitant'; // Make sure the path is correct
import fetchRequest from "../../axiosRequest";
import getProjectIdentifier from '../../utils/getProjectIdentifier';

import { envVariables } from "../../config"

function Chatbot() {
  const { host } = envVariables;
  const [chatboxActive, setChatboxActive] = useState(false);
  const [currentStep, setCurrentStep] = useState(4);
  const [messages, setMessages] = useState([{ type: 'assistant', text: 'I am your personal assistant to help you become more productive. Ask me anything.' }]);
  const [userMessage, setUserMessage] = useState('');
  const [autoCommandsVisible, setAutoCommandsVisible] = useState(true); // Define autoCommandsVisible
  const [chatNodesVisible, setChatNodesVisible] = useState(false);
  // const [chatVisible, setChatVisible] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [notificationCount, setNotificationCount] = useState(0);
  const [step4Open, setStep4Open] = useState(false); // Track whether step 4 is open
  const [threadId, setThreadId] = useState(''); // Track the thread Id for the current chat
  const [miximize, setMiximize] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);


  // Initialize chatRoomRef with useRef
  const chatRoomRef = useRef(null);

  const toggleChat = () => {
    setChatboxActive((prevState) => !prevState);
    if (chatboxActive) {
      setNotificationCount(0); // Reset notification count when chat is opened
    }
  };
  const handleChatbotToggle = () => {
    setChatboxActive((prevChatboxActive) => !prevChatboxActive);
    if (!chatboxActive) {
      setNotificationCount(0); // Reset notification count when chat is opened
    }
  };
  const handleInputChange = (event) => {
    setMessageInput(event.target.value);
  };

  // const handleSendMessage = () => {
  //   if (messageInput.trim() === '') return;

  //   //get the thread_id from the local storage
  //   if (localStorage.getItem('threadIc')) {
  //     setThreadId(localStorage.getItem('threadIc'));
  //   }

  //   // Update state with the user's message
  //   setMessages([...messages, { type: 'user', text: messageInput }]);
  //   setMessageInput('');

  //   let AIResponse = ""

  //   // copy current state of message
  //   const conversation = [...messages];
  //   //first, set a "...." message to show the user that the AI is typing
  //   setMessages((conversation) => [
  //     ...conversation,
  //     { type: 'assistant', text: '...' },
  //   ]);
  //   // Send the user's message to the AI service virtualAssistant/response
  //   // the response is a json object with a parameter called "result", containing the AI text response

  //   const userContext = "username: " + localStorage.getItem('username') + ", email: " + localStorage.getItem('email') + ", firstName: " + localStorage.getItem('firstName') + ", lastName: " + localStorage.getItem('lastName') + ", company: " + localStorage.getItem('company')
  //   const additionalData = ""
  //   fetchRequest("virtualAssistant/GPTResponse", "POST", {projectIdentifier: getProjectIdentifier(), userText: messageInput, userContext: userContext, additionalData: additionalData, threadId: threadId})
  //     .then((data) => {
  //       AIResponse = data.data.result;
  //           // we need to extract the text from the result, fix the url links and update the thread_id
  //       const result = AIResponse;
  //       console.log(result)
  //       AIResponse = result.text;

  //       if (result.thread_id) {
  //         setThreadId(result.thread_id);
  //         localStorage.setItem('threadId', result.thread_id);
  //       }

  //       console.log(data)
  //       // remove "..." in the chat
  //       setMessages((conversation) => conversation.slice(0, -1));
  //       setMessages((conversation) => [
  //         ...conversation,
  //         { type: 'assistant', text: AIResponse },
  //       ]);
  //     })
  //     //in case of error, output an error message
  //     .catch((error) => {
  //       console.error(error);
  //       // remove "..." in the chat
  //       setMessages((conversation) => conversation.slice(0, -1));
  //       setMessages((conversation) => [
  //         ...conversation,
  //         { type: 'assistant', text: 'Sorry, something went wrong.' },
  //       ]);
  //     });

  // };
  const handleSendMessage = () => {
    if (messageInput.trim() === '') return;

    // Get the thread_id from the local storage
    if (localStorage.getItem('threadId')) {
      setThreadId(localStorage.getItem('threadId'));
    }

    // Update state with the user's message
    setMessages([...messages, { type: 'user', text: messageInput }]);
    setMessageInput('');

    let AIResponse = "";

    // Copy current state of messages
    const conversation = [...messages];
    // First, set a "...." message to show the user that the AI is typing
    setMessages((conversation) => [
      ...conversation,
      { type: 'assistant', text: '...' },
    ]);

    // Prepare user context
    const userContext = "username: " + localStorage.getItem('username') + ", email: " + localStorage.getItem('email') + ", firstName: " + localStorage.getItem('firstName') + ", lastName: " + localStorage.getItem('lastName') + ", company: " + localStorage.getItem('company');
    const additionalData = "";

    // Create a FormData object to hold the file and other data
    const formData = new FormData();
    formData.append('projectIdentifier', getProjectIdentifier());
    formData.append('userText', messageInput);
    formData.append('userContext', userContext);
    formData.append('additionalData', additionalData);
    formData.append('threadId', threadId);

    // Append the selected file if any
    if (selectedFile) {
      formData.append('file', selectedFile);
    }
    else {
      formData.append('file', null);
    }

    fetchRequest("virtualAssistant/GPTResponse", "POST", { projectIdentifier: getProjectIdentifier(), userText: messageInput, userContext: userContext, additionalData: additionalData, file: selectedFile, threadId: threadId })
      .then((data) => {
        AIResponse = data.data.result;
        // Extract text from the result, fix the URL links, and update the thread_id
        const result = AIResponse;
        console.log(result);
        AIResponse = result.text;

        if (result.thread_id) {
          setThreadId(result.thread_id);
          localStorage.setItem('threadId', result.thread_id);
        }

        console.log(data);
        // Remove "..." in the chat
        setMessages((conversation) => conversation.slice(0, -1));
        setMessages((conversation) => [
          ...conversation,
          { type: 'assistant', text: AIResponse },
        ]);
      })
      // In case of error, output an error message
      .catch((error) => {
        console.error(error);
        // Remove "..." in the chat
        setMessages((conversation) => conversation.slice(0, -1));
        setMessages((conversation) => [
          ...conversation,
          { type: 'assistant', text: 'Sorry, something went wrong.' },
        ]);
      });
  };

  const handleFileChange = (file) => {
    setSelectedFile(file);
  };

  const handleGetStarted = () => {
    setCurrentStep(4);
    setAutoCommandsVisible(true);
  };
  const handleAutoCommandClick = (val) => {
    // Clear all messages and show the selected auto command as a chat message
    setMessages([{ sender: 'user', message: val }]);
    setAutoCommandsVisible(false);
    setChatNodesVisible(true);
  };
  // Check if chatRoomRef exists before attempting to access scrollHeight
  useEffect(() => {
    if (chatRoomRef.current) {
      chatRoomRef.current.scrollTop = chatRoomRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className={`chatbot-box fixed right-10 bottom-10 ${chatboxActive ? 'active' : ''} ${miximize ? "maximized" : ""}`}>
      <button className="chatbot_btn active bg-gradient-to-b from-blue-500 to-blue-800 p-4 rounded-full shadow-md" onClick={handleChatbotToggle}>
        <span className='circle'></span>
        <img src={`${process.env.PUBLIC_URL}/images/AppScreen/chatbot.svg`} alt="robot_icon" />
      </button>

      {chatboxActive && (
        <div className="chatbot_box">
          <button className="close absolute top-2 right-2 text-red-600 pt-5 pr-5" onClick={toggleChat}>
            <img src={`${process.env.PUBLIC_URL}/images/AppScreen/cancel.svg`} className="mx-auto" alt="robot_icon" />
          </button>

          <div className="before_chat text-center">
            {currentStep <= 3 && (
              <div className="mb-10">
                <img src={`${process.env.PUBLIC_URL}/images/AppScreen/logo.svg`} className="mx-auto" alt="robot_icon" />
              </div>
            )}

            {/* {currentStep === 4 && (
              
            )} */}

            {currentStep === 4 && (
              <VirtualAssistant
                ref={chatRoomRef}
                chatVisible={chatboxActive}
                messages={messages}
                messageInput={messageInput}
                setMessageInput={setMessageInput}
                handleInputChange={handleInputChange}
                handleSendMessage={handleSendMessage}
                handleAutoCommandClick={handleAutoCommandClick}
                togglechat={toggleChat}
                setMiximize={setMiximize}
                miximize={miximize}
                handleFileChange={handleFileChange}
              />
            )}

            <div className="steps relative h-36 overflow-hidden" >
              {[1, 2, 3].map((step) => (
                <div key={step} className={`step step${step} absolute transition-transform`} style={{ transform: `translateX(${(currentStep - step) * -100}%)` }}>
                  <h3 className="text-center  text-20 font-extrabold text-black tracking-wide font-microgramma mb-5">Welcome to Mavryck’s Personal Assistant</h3>
                  <p className="text-gray-600 font-roboto font-regular text-black ">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis malesuada sollicitudin amet ac nunc. Diam vel viverra lectus tempor scelerisque interdum tristique.
                  </p>
                </div>
              ))}
            </div>

            {currentStep < 4 && (
              <div className="navigation_btns flex justify-between mt-20">
                <button
                  className={`prev ${currentStep === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-D9D9D9'} min-w-12 h-12 rounded-full shadow-md`}
                  onClick={() => setCurrentStep(currentStep - 1)}
                  disabled={currentStep === 1}
                >
                  <img src={`${process.env.PUBLIC_URL}/images/AppScreen/prev.svg`} className="mx-auto" alt="prev" />
                </button>
                {currentStep < 3 && (
                  <button
                    className={`next bg-D9D9D9 min-w-12 h-12 rounded-full shadow-md`}
                    onClick={() => setCurrentStep(currentStep + 1)}
                  >
                    <img src={`${process.env.PUBLIC_URL}/images/AppScreen/next.svg`} className="mx-auto" alt="next" />
                  </button>
                )}
                {currentStep === 3 && (
                  <button className="getStarted bg-green-500 text-white min-w-12 h-12 rounded-full" onClick={handleGetStarted}>
                    Get Started
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Chatbot;
